/* eslint-disable import/no-anonymous-default-export */
import { LOCALES } from "../locales";

export default {
  [LOCALES.ENGLISH]: {
    hello: "Hi, {name}!",
    login: "Login",
    username: "Username",
    password: "Password",
    loginbutton: "Log in",
    home: "Home",
    dashboard: "dashboard",
    users: "Users",
    horses: "Horses",
    places: "places",
    reproduction: "Reproduction",
    stock: "Stock",
    calendar: "Calendar",
    settings: "Settings",
    page: "Page",
    of: "of",
    show: "Show",
    records: "records",
    search: "Search",
    search_help_text: "You must enter at least {nro} characters...",
    city: "city",
    father: "Father",
    mother: "Mother",
    location: "Location",
    locations: "Locations",
    "born on": "Born on",
    "clinic history": "Clinic history",
    "technical data": "technical data",
    sex: "Sex",
    fur: "Fur",
    "general data": "general data",
    classification: "classification",
    responsable: "Responsable",
    passport: "passport",
    "AAFE sheet": "AAFE sheet",
    "latest appointment": "latest appointment",
    awards: "awards",
    "dressage start": "dressage start",
    "dressage end": "dressage end",
    observations: "observations",
    backhorses: "Back to horse list",
    backhorsedata: "Back to horse data",
    birthday: "Birthday",
    age: "Age",
    "latest jump": "latest jump",
    "latest training": "latest training",
    appointments: "appointments",
    interventions: "interventions",
    treatments: "treatments",
    mediacenter: "mediacenter",
    professional: "professional",
    diagnosis: "diagnosis",
    diagnoses: "diagnoses",
    start: "Start",
    end: "End",
    anamnesis: "anamnesis",
    next: "next",
    previous: "previous",
    today: "today",
    month: "month",
    week: "week",
    day: "day",
    eventsDay: "events of the day",
    "new event": "new event",
    "edit event": "edit event",
    title: "Title",
    description: "Description",
    event: "Event",
    "no data": "no data",
    unknown: "Unknown",
    name: "Name",
    "confirm delete": "Are you sure you want to delete?",
    "new horse": "new horse",
    mare: "Mare",
    veterinarian: "Veterinarian",
    rider: "Rider",
    AAFE: "AAFE",
    pedigree: "pedigree",
    "successful creation": "successful creation",
    "show web": "show web",
    "edit horse": "edit horse",
    "successful editing": "successful editing",
    address: "Address",
    phone: "Phone",
    personCharge: "Person Charge",
    actions: "Actions",
    "edit location": "Edit location",
    "new location": "New location",
    "new user": "New user",
    "edit user": "Edit user",
    role: "Role",
    administrator: "Administrator",
    manager: "Manager",
    firstName: "First name",
    lastname: "Last name",
    email: "email",
    repassword: "Repeat password",
    horse: "horse",
    type: "type",
    registrationNumber: "registrationNumber",
    "check password": "check password",
    date: "date",
    insertDescription: "Insert description here...",
    "new appointment": "New appointment",
    "edit appointment": "Edit appointment",
    issue: "issue",
    veterinarianAssistant: "Veterinarian assistant",
    errorContact: "There was an error. Please contact support.",
    professionalAssistant: "Assistant",
    addDiagnosis: "Add diagnosis",
    addIntervention: "Add intervention",
    editDiagnosis: "Edit diagnosis",
    newIntervention: "New intervention",
    editIntervention: "Edit intervention",
    insertAnamnesis: "insert anamnesis here...",
    "new diagnosis": "New diagnosis",
    "edit diagnosis": "Edit diagnosis",
    editAppointment: "Edit appointment",
    editTreatment: "Edit treatment",
    addTreatment: "Add treatment",
    interventionType: "Intervention Type",
    "attach file": "attach file",
    "No file selected": "No file selected",
    attach: "attach",
    "Replace file": "Replace file",
    "Upload file": "Upload file",
    "Replace files": "Replace files",
    "Upload files": "Upload files",
    "new media": "new media",
    cycle: "Cycle",
    length: "Length",
    stallion: "Stallion",
    castrated: "Castrated",
    "Horse created": "Horse created",
    general: "General",
    competition: "Competition",
    education: "Education",
    smithy: "Smithy",
    vaccination: "Vaccination",
    deworming: "Deworming",
    review: "Review",
    playback: "Playback",
    radiography: "Radiography",
    surgery: "Surgery",
    published: "Published",
    draft: "Draft",
    news: "News",
    insertObservation: "Insert observation here...",
    "insert url": "Insert document link",
    insertComment: "Insert comment",
    "new classification": "New classification",
    "edit classification": "Edit classification",
    "next classification": "Next classification",
    relatedHorseId: "Related horse",
    "initial classification": "Initial classification",
    view: "view",
    edit: "edit",
    delete: "delete",
    status: "Status",
    active: "Active",
    jump: "Jump",
    club: "Club",
    training: "Training",
    trainings: "Trainings",
    meters: "meters",
    exit: "Exit",
    "insert stock classification":
      "Necessary to insert stock by the selected classification",
    classificationDate: "Classification date",
    "change password": "Change password",
    oldpassword: "Old password",
    newpassword: "New password",
    total: "Total",
    user: "User",
    changeclassification: "Change classification",
    mole: "Powered by Mole-Dev",
    relatedHorse: "Related horse",
    lastStatus: "Last status",
    startDate: "Start date",
    endDate: "End date",
    process: "Process",
    comment: "Comment",
    cannotdelete: "Cannot delete this record",
    surgical: "Surgical",
    locationDate: "Transfer date",
    alazán: "Alazán",
    tordillo: "Tordillo",
    zaino: "Zaino",
    transferDate: "Transfer Date",
    changeLocation: "Change Location",
    transferHorse: "Transfer horse",
    loading: "Loading",
    remove: "Remove",
    change: "Change",
    birthday_short: "Birthday",
    chape: "Chape",
    "mother mare": "Mother mare",
    "inseminated with father": "Inseminated with father",
    "insemination date": "Insemination date",
    "estimated birthday": "Estimated birthday",
    "pregnant mares": "Pregnant mares",
    pregnantMares: "Pregnant mares",
    inseminatedMares: "Inseminated mares",
    transferredMares: "Transferred mares",
    foalAtFootMares: "Foal at foot mares",
    pregnant: "Pregnant",
    inseminated: "Inseminated",
    transferred: "Transferred",
    foalAtFoot: "Foal at foot",
    served: "Served",
    "best case": "Best case",
    faults: "Faults",
    result: "Result",
    horse_List: "Horse_List",
    pregnant_mares: "Pregnant_mares",
    fibroscopy: "Fibroscopy",
    odontology: "Odontology",
    ultrasound: "Ultrasound",
    infiltration: "Infiltration",
    "platelet rich plasma": "platelet rich plasma",
    "insert numbers only": "Insert numbers only",
    "access denied to page": "Access denied to page",
    "new passport": "New passport",
    passports: "Passports",
    showInWeb: "Show in Web",
    export: "Export",
    reports: "Informes",
    owner: "Owner",
    NewAppointment: "{0} new appointments were created",
    "download files": "Download files",
  },
};
